<template>
  <p v-if="label">{{ label }}</p>
  <editor :disabled="isDisabled" api-key="6pd16nn5jw8fqh5aayva56pirbe9c17rlgtbs6mpx6n81ly5" :init="init" v-model="new_value"/>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  props: ["modelValue", "height", "label", "margins", "isDisabled"],
  components: {
    editor: Editor,
  },
  mounted() {
    this.new_value = this.modelValue;
  },
  data() {
    return {
      new_value: null,
    };
  },
  computed: {
    init() {
      return {
        language: 'en',
        height: this.height ? this.height : 500,
        inline: false,
        paste_data_images: true,
        lineheight_formats: "0.4 0.5 0.6 0.7 0.8 0.9 1.0 1.1 1.2 1.3 1.4",
        content_style: "body { line-height: 1.4; padding-top:" + this.margins.margin_top + "mm;background-repeat: no-repeat;background-size: 820px 1160px;padding-bottom:" + this.margins.margin_bottom + "mm;padding-left:" + this.margins.margin_left + "mm;padding-right:" + this.margins.margin_right + "mm;} .pagebreak{height:" + this.margins.margin_top + "mm;}",
        menu: {
          file: { title: 'File', items: 'newdocument restoredraft | preview | importword exportpdf exportword | print | deleteallconversations' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
          view: { title: 'View', items: 'code revisionhistory | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
          insert: { title: 'Insert', items: 'image link media addcomment pageembed codesample inserttable | math | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
          tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
          table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
          help: { title: 'Help', items: 'help' }
        },
        plugins: [
          'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
          'searchreplace', 'wordcount', 'visualblocks', 'visualchars', 'code', 'fullscreen', 'insertdatetime',
          'media', 'table', 'emoticons', 'help'
        ],
        toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media | forecolor backcolor emoticons',
      };
    },
  },
  watch: {
    new_value: {
      handler() {
        this.$emit("change", this.new_value);
      },
    },
    modelValue: {
      handler() {
        this.new_value = this.modelValue;
      },
    },
  },
};
</script>

<style>
.tox-notifications-container {
  display: none !important;
}
.tinymce-content p {
  padding: 0;
  margin: 2px 0;
}

.tox-tinymce--toolbar-sticky-on .tox-editor-header {
  top: 60px !important;
}

.tox-tinymce-aux {
  z-index: 2500 !important;
}

.pagebreak {
  background: #f4f4f4;
}
</style>